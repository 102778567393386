import { Close, Menu } from "@mui/icons-material";
import { AppBar, Button, Toolbar, Box, Avatar } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../components/Logo";
import FormModal from "../components/FormModal";

const Header = ({ whiteBg }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);
  function handleClose() {
    setOpenForm(false)
  }
  function handleOpenForm() {
    setOpenForm(true)
  }
  return (
    <div className="relative">
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
      >
        <Toolbar
          sx={{ flexWrap: "wrap", minHeight: "90px !important" }}
          className={
            "flex justify-between items-center md:justify-start md:space-x-5"
          }
        >
          <div className="flex justify-start lg:w-0 lg:flex-1 md:py-4 md:pl-20">
            <Logo handleOpenForm={handleOpenForm} black={whiteBg ? true : false} />
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Button
              style={{ padding: "6px 0px", minWidth: "40px" }}
              onClick={() => setOpen(true)}
            >
              <Menu className="logo-blue " />
            </Button>
          </div>
          <div className="z-[500] hidden md:flex justify-center items-center bg-white rounded-xl h-[38px] py-1 px-3">
            <Link to={"/help"} className="text-[#727272] text-[14px]">
              {t("Help")}
            </Link>
          </div>
          <div className={isLoggedIn ? "z-[500] h-[38px] hidden md:flex justify-center items-center bg-white rounded-xl w-[330px] py-1 px-3 justify-between" :
            "z-[500] h-[38px] hidden md:flex justify-center items-center bg-white rounded-xl w-[150px] py-1 px-3 justify-center"}>


            {isLoggedIn && (
              <>
                <Link
                  to={"/contacts"}
                  className=" text-[#727272] text-[14px] "
                >
                  {t("Contacts")}
                </Link>
                <Link
                  to={"/transfers"}
                  className="text-[#727272] text-[14px] "
                >
                  {t("Transfers")}
                </Link>
              </>
            )}

            <div className="">
              {isLoggedIn ? (
                <>
                  <Link
                    to={"/account"}
                    className="logo-blue flex items-center"
                  >
                    {user.first_name}
                    <Avatar
                      sx={{ width: 30, height: 30, marginLeft: 1 }}
                      src={user.avatar || ""}
                    />
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to={"/login"}
                    className="text-[#727272] text-[14px]"
                  >
                    {t("Sign In")}
                  </Link>
                  <span> </span>

                  <span className="text-[#727272] text-[14px]">/</span>
                  <span> </span>

                  <Link
                    to={"/register"}
                    className="text-[#727272] text-[14px]"
                  >
                    {t("Sign Up")}
                  </Link>
                </>
              )}
            </div>
          </div>
        </Toolbar>
      </AppBar>

      {open && (
        <Box className="z-50 absolute bg-white rounded-lg m-2 top-0 inset-x-0 p-3 transition transform origin-top-right md:hidden shadow-lg">
          <div className="p-3 flex justify-between">
            <div>
              <Logo handleOpenForm={handleOpenForm} />
            </div>
            <div className="-mr-2">
              <Button
                style={{ padding: "6px 0px", minWidth: "40px" }}
                onClick={() => setOpen(false)}
              >
                <Close />
              </Button>
            </div>
          </div>
          <nav className="mt-6 ">
            <Link
              to={"/"}
              className="w-full flex items-center justify-center px-4 py-2 m-1 "
            >
              {t("Home")}
            </Link>
            <Link
              to={"/"}
              className="w-full flex items-center justify-center px-4 py-2 m-1 "
            >
              {t("Help")}
            </Link>
            {isLoggedIn && (
              <>
                <Link
                  to={"/contacts"}
                  className="w-full flex items-center justify-center px-4 py-2 m-1 "
                >
                  {t("Contacts")}
                </Link>
                <Link
                  to={"/transfers"}
                  className="w-full flex items-center justify-center px-4 py-2 m-1 "
                >
                  {t("Transfers")}
                </Link>
              </>
            )}
          </nav>
          <div className="py-6 px-5 space-y-6">
            {isLoggedIn ? (
              <>
                <Link
                  to={"/account"}
                  className="text-base font-medium logo-blue "
                >
                  {t("Account")}
                </Link>
              </>
            ) : (
              <>
                <Link
                  to={"/register"}
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  {t("Sign Up")}
                </Link>
                <p className="mt-6 text-center text-base font-medium logo-blue">
                  {t("Already have an account?")}
                  <Link
                    to={"/login"}
                    className="text-blue-600 hover:text-blue-500"
                  >
                    {" "}
                    {t("Sign In")}
                  </Link>
                </p>
              </>
            )}
          </div>
        </Box>
      )}
      <FormModal handleClose={handleClose} open={openForm}></FormModal>
    </div>
  );
};

export default Header;
