import { CloseRounded } from "@mui/icons-material";
import { AppBar, Avatar, Button, Toolbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { sidebarClose } from "../../store/sidebar/sidebarSlice";

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);
  const info = useSelector((state) => state.info);

  const navigateTo = (to = null) => {
    dispatch(sidebarClose());
    setTimeout(() => {
      navigate(to || info.navigate);
    }, 500);
  };

  return (
    <div className="relative">
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar
          sx={{ flexWrap: "wrap", minHeight: "90px !important" }}
          className="flex justify-between items-center py-6 md:justify-start md:space-x-10"
        >
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Button
              onClick={() => {
                navigateTo();
              }}
            >
              <CloseRounded />
            </Button>
          </div>
          {/* <nav className="hidden md:flex space-x-10">
         
            <Link to={"/help"} className="text-base font-medium logo-blue ">
              {t("Help")}
            </Link>
            {isLoggedIn && (
              <>
                <Link
                  to={"/transfers"}
                  className="text-base font-medium logo-blue "
                >
                  {t("Transfers")}
                </Link>
                <Link
                  to={"/contacts"}
                  className="text-base font-medium logo-blue "
                >
                  {t("Contacts")}
                </Link>
              </>
            )}
          </nav>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            {isLoggedIn ? (
              <>
                <Link
                  to={"/account"}
                  className="text-base font-medium logo-blue flex items-center"
                >
                  {user.first_name}
                  <Avatar
                    sx={{ width: 30, height: 30, marginLeft: 1 }}
                    src={user.avatar || ""}
                  />
                </Link>
              </>
            ) : (
              <>
                <Link
                  to={"/login"}
                  className="whitespace-nowrap text-base font-medium logo-blue "
                >
                  {t("Sign In")}
                </Link>
                <Link
                  to={"/register"}
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-logo-blue"
                >
                  {t("Sign Up")}
                </Link>
              </>
            )}
          </div> */}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
