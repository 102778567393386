import { createTheme } from "@mui/material";

const theme = createTheme({
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#d32f2f'
        }
      }
    }
  },
  palette: {
  primary: {
    main: '#4076bc',
  },
  gray: {
    main: '#f5f5f5',
  }
},
});
export default theme;