import {
  ArrowCircleLeftOutlined,
  Check,
  CheckBoxOutlined,
  Close,
  Delete,
  Edit,
  HighlightOff,
} from "@mui/icons-material";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  addContact,
  deleteContact,
  editContact,
} from "../../store/contact/contactSlice";
import { debug } from "../../utils";
import {
  addRecipient,
  removeRecipient,
} from "../../store/uploader/uploaderSlice";
import ConfirmDialog from "../../components/ConfirmDialog";
import { useState } from "react";

const ContactItem = ({
  data = { full_name: "", email: "" },
  id = 0,
  edit,
  setEdit,
  refreshList=()=>{}
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const recipients = useSelector((state) => state.uploader.recipients);
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const validation = yup.object({
    email: yup
      .string()
      .email(t("{{field}} Email is not a valid email", { field: "" }))
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        t("{{field}} Email is not a valid email", { field: "" })
      )
      .required(t("{{field}} Email is required", { field: "" })),
  });

  const {
    handleSubmit,
    control,
    formState

  } = useForm({
    defaultValues: data,
    resolver: yupResolver(validation),
  });

  const onSubmit = (data) => {
    debug("contact", { id, ...data });
    if (id === 0) {
      dispatch(addContact(data));
      refreshList()
    } else {
      dispatch(editContact({ id, ...data }));
    }
    setEdit(-1);
  };
  function handleCloseDeleteModal() {
    setOpenDeleteModal(false)
  }
  function agreeDelete() {
    dispatch(deleteContact(id));
  }
  return (
    <>
      <ConfirmDialog open={openDeleteModal} handleClose={handleCloseDeleteModal} agree={agreeDelete} />
      {edit === id || id === 0 ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-12 border-b" style={{ padding: 10 }}>
            <div className="col-span-5 px-1">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={typeof formState.errors.email !== "undefined"}
                    id="email"
                    size="small"
                    placeholder={t("Email")}
                    variant="outlined"
                    fullWidth
                    label={formState.errors.email && t(formState.errors.email.message)}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-span-5 px-1">
              <Controller
                name="full_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="full_name"
                    size="small"
                    placeholder={t("Full Name")}
                    variant="outlined"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
            <div className="col-span-2 pl-1 flex justify-end">
              <IconButton className="disabled:opacity-30" disabled={JSON.stringify(formState?.dirtyFields) === '{}'} type="submit">
                <CheckBoxOutlined color="success" />
              </IconButton>
              <IconButton onClick={() => setEdit(-1)}>
                <Close color="error" />
              </IconButton>
            </div>
          </div>
        </form>
      ) : (
        <div
          className="group flex justify-between border-b"
          style={{ padding: 4.5 }}
        >
          <div className="flex justify-start">
            <div className="grid content-center">
              {recipients.findIndex((item) => item === data.email) > -1 ? (
                <>
                    <IconButton
                      className="flex group-hover:hidden"
                      onClick={() => {
                        dispatch(removeRecipient(data.email));
                      }}
                    >
                      <Check fontSize="large" className="text-gray-500" />
                    </IconButton>
                    <Tooltip title="Alıcılardan Çıkar">

                    <IconButton
                      className="hidden group-hover:inline-flex"
                      onClick={() => {
                        dispatch(removeRecipient(data.email));
                      }}
                    >
                      <HighlightOff fontSize="large" className="text-gray-500" />
                    </IconButton>
                    </Tooltip>
                </>
              ) : (
                <Tooltip title="Alıcılara Ekle">
                  <IconButton
                    onClick={() => {
                      dispatch(addRecipient(data.email));
                    }}
                  >
                    <ArrowCircleLeftOutlined
                      fontSize="large"
                      className="text-gray-500"
                    />
                  </IconButton>
                </Tooltip>

              )}
            </div>
            <div className="grid content-center">
              <p className="text-gray-700">{data.email}</p>
              <small className="text-gray-400">{data.full_name}</small>
            </div>
          </div>
          <div className="grid content-center">
            <div>
              <IconButton
                className="hidden group-hover:inline-flex"
                onClick={() => {
                  setEdit(id);
                }}
              >
                <Edit className="text-gray-500" />
              </IconButton>
              <IconButton
                className="hidden group-hover:inline-flex"
                onClick={() => {
                  setOpenDeleteModal(true)
                }}
              >
                <Delete className="text-gray-500" />
              </IconButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactItem;
