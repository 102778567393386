import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { useEffect, useState } from 'react';
import axios from "../../configs/axios";


const TermsOfService = ({ open = false, handleClose = () => { } }) => {
  const [contract, setContract] = useState(null);

  async function getContractData() {
    const response = await axios.get(process.env.REACT_APP_CMS_API_URL + '/pages/show/?page_id=9')
    setContract(response?.data?.description)
  }


  useEffect(() => {
    if (open) {
      getContractData()
    }
  }, [open])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className='rounded-3xl w-[100%] max-h-[90vh] overflow-scroll md:w-[50%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 bg-white p-4'>
        <div class="max-w-md mx-auto mb-8 mt-4">
          <span dangerouslySetInnerHTML={{ __html: contract }} />
        </div>


        <div class="flex justify-end w-full">
          <button onClick={handleClose} class={"bg-gray-700 disabled:bg-gray-500 text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[35%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 rounded-full"}>Kapat</button>
        </div>

      </Box>
    </Modal>

  );
};

export default TermsOfService;
