import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Layout from "../../layout";
import Header from "../../layout/Header";
import { onSidebarCloseNavigate } from "../../store/info/infoSlice";
import { sidebarOpen } from "../../store/sidebar/sidebarSlice";
import axios from "../../configs/axios";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
const Help = () => {
  const [faqs, setFaqs] = useState();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(onSidebarCloseNavigate("/"));
    dispatch(sidebarOpen());
    getFaqsData()

  }, [dispatch]);



  async function getFaqsData() {
    const response = await axios.get(process.env.REACT_APP_CMS_API_URL+'/faqs')
    setFaqs(response?.data)
  }


  return (
    <Layout
      header={<Header />}
      sidebarContent
      className={"fullscreen home-image"}
      bgImg={true}

    >
      <h2 className="py-10 text-2xl px-1">Sıkca Sorulan Sorular (SSS)</h2>
      {faqs?.map(item => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            <span dangerouslySetInnerHTML={{__html: item.answer}} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Layout>
  );
};
export default Help;
