import uzmanLogo from "../../assets/images/uzmanposta.png"  
import fourAlabs from "../../assets/images/4alabs.png"  

const PartnerShips = () => {

return (
    <footer className="footer" >
      <div>
        <p>Hosting Partner</p>
        <a href="https://uzmanposta.com/" target="_blank" rel="noopener noreferrer">
          <img className="partner-logo" src={uzmanLogo} alt="Uzman Posta" />
        </a>
      </div>
      <div>
        <p>Powered by</p>
        <a href="https://www.4alabs.io/" target="_blank" rel="noopener noreferrer">
          <img className="partner-logo" src={fourAlabs} alt="4A Labs" />
        </a>
      </div>
    </footer>
  )
}

export default PartnerShips