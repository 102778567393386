import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../configs/axios";
import { debug } from "../../utils";
import { resetLoading, setLoading, setSuccess } from "../info/infoSlice";

export const getContacts = createAsyncThunk(
  "contacts/getContacts",
  async (searchParams = {}, { dispatch, getState }) => {
    dispatch(setLoading("getContacts"));
    const params = new URLSearchParams();
    params.set("search", getState().contact.params.search);
    params.set("start", getState().contact.params.start);
    params.set("count", getState().contact.params.count);
    params.set("sort", getState().contact.params.sort);
    const response = await axios.get("/contacts?" + params.toString());
    debug("getContacts", response);
    dispatch(resetLoading("getContacts"));
    if (response.status === 200) {
      return response.data;
    }
  }
);

export const addContact = createAsyncThunk(
  "contacts/addContact",
  async (contact, { dispatch }) => {
    dispatch(setLoading("addContact"));
    let response = await axios.post("/contacts", contact);
    debug("addContact", response);
    dispatch(resetLoading("addContact"));
    if (response.status === 201) {
      dispatch(
        setSuccess({
          message: "Contact created",
        })
      );
      return response.data;
    }
  }
);

export const getContact = createAsyncThunk(
  "contacts/getContact",
  async (id, { dispatch }) => {
    dispatch(setLoading("getContact"));
    let response = await axios.get(`/contacts/${id}`);
    dispatch(resetLoading("getContact"));
    if (response.status === 200) {
      return response.data;
    }
  }
);

export const editContact = createAsyncThunk(
  "contacts/editContact",
  async (obj, { dispatch }) => {
    dispatch(setLoading("editContact"));
    const { id, ...data } = obj;
    let response = await axios.put(`/contacts/${id}`, data);
    debug("editContact", response);
    dispatch(resetLoading("editContact"));
    if (response.status === 200) {
      dispatch(
        setSuccess({
          message: "Contact updated",
        })
      );
      return response.data;
    }
  }
);

export const deleteContact = createAsyncThunk(
  "contacts/deleteContact",
  async (id, { dispatch }) => {
    dispatch(setLoading("deleteContact"));
    let response = await axios.delete(`/contacts/${id}`);
    debug("deleteContact", response);
    dispatch(resetLoading("deleteContact"));
    if (response.status === 204) {
      dispatch(
        setSuccess({
          message: "Contact deleted",
        })
      );
      return { id };
    }
  }
);

const initialState = {
  total: 0,
  params: { search: "", sort: "created_at", start: 0, count: 20 },
  list: null,
};

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setParams: (state, action) => {
      state.params = action.payload;
    },
    resetContactState: (state) => {
      state.total = initialState.total;
      state.params = initialState.params;
      state.list = initialState.list;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContacts.fulfilled, (state, action) => {
        const response = action.payload;
        state.total = response?.total || 0;
        if (!!state.params.search) {
          state.list = response.data || [];
        } else if (response.total > (state.list?.length || 0)) {
          const diff = (state.list || []).filter(
            (item) =>
              -1 ===
              response.data?.findIndex(
                (el) => parseInt(el.id) === parseInt(item.id)
              )
          );
          state.list = [...diff, ...response.data];
        }
      })
      .addCase(addContact.fulfilled, (state, action) => {
        if (!state.list) state.list = [];
        state.list.unshift(action.payload);
        state.total = state.total + 1;
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        const index = state.list?.findIndex(
          (item) => parseInt(item?.id) === parseInt(action.payload?.id)
        );
        state.list?.splice(index, 1);
        state.total = state.total - 1;
      })
      .addCase(editContact.fulfilled, (state, action) => {
        const index = state.list?.findIndex(
          (item) => parseInt(item?.id) === parseInt(action.payload?.id)
        );
        debug("index", index);
        state.list[index] = action.payload;
      });
  },
});

export const { setParams, resetContactState } = contactSlice.actions;
export default contactSlice.reducer;
