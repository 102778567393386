import { Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import VerticalTabs from "../../components/Tabs/VerticalTabs";
import Layout from "../../layout";
import Header from "../../layout/Header";
import { logout } from "../../store/auth/authSlice";
import InfoTab from "./InfoTab";
import SecurityTab from "./SecurityTab";

const Account = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const info = useSelector((state) => state.info);

  return (
    <Layout
      header={<Header />}
      sidebarContent
      className={"fullscreen home-image"}
      bgImg={true}
    >
      <VerticalTabs
        right
        tabs={[
          {
            label: t("Profile Informations"),
            component: <InfoTab />,
          },
          {
            label: t("Password and security"),
            component: <SecurityTab />,
          },
        ]}
      >
        <div className="m-4 my-5">
          <Button
            className="capitalize"
            onClick={() => {
              dispatch(logout());
            }}
            variant="contained"
            fullWidth
            disabled={info.loading?.logout}
            startIcon={
              info.loading?.logout && <CircularProgress size={"1rem"} />
            }
          >
            {t("Log out")}
          </Button>
        </div>
      </VerticalTabs>
    </Layout>
  );
};
export default Account;
