import { Email, Person, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { register, resetSession } from "../../store/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import InfoMessage from "../../components/InfoMessage";
import RegisteredScreen from "./RegisteredScreen";
import { useEffect, useRef, useState } from "react";
import Layout from "../../layout";
import Header from "../../layout/Header";
import { resetInfo } from "../../store/info/infoSlice";
import Kvkk from "../Contracts/Kvkk";
import PrivacyAndCookies from "../Contracts/PrivacyAndCookies";
import TermsOfService from "../Contracts/TermsOfService";
const Register = () => {

  const registered = useSelector((state) => state.auth.registered);
  const [openKvkk, setOpenKvkk] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);


  const info = useSelector((state) => state.info);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const formRef = useRef(null);
  const submitRef = useRef(null);

  const validation = yup.object({
    email: yup
      .string()
      .email(t("{{field}} Email is not a valid email", { field: "" }))
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        t("{{field}} Email is not a valid email", { field: "" })
      )
      .required(t("{{field}} Email is required", { field: "" })),
    password: yup
      .string()
      .required(t("{{field}} is required", { field: t("Password") }))
      .min(6, t("{{field}} must be at least 6 characters", { field: t("Password") }))
      .matches(/[A-Za-z]/, t("{{field}} must contain at least one letter", { field: t("Password") })),

    first_name: yup
      .string()
      .required(t("{{field}} is required", { field: t("First Name") })),
    last_name: yup
      .string()
      .required(t("{{field}} is required", { field: t("Last Name") })),
  });

  const {

    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: { email: "", password: "", first_name: "", last_name: "" },
    resolver: yupResolver(validation),
  });

  const onSubmit = (data) => {
    dispatch(resetInfo());
    dispatch(resetSession("registered"));
    dispatch(register({ ...data }));
  };

  useEffect(() => {
    dispatch(resetSession("registered"));
    dispatch(resetInfo());
  }, [dispatch]);

  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      event.preventDefault();
      const form = formRef.current;
      const inputs = Array.from(form.querySelectorAll('input'));
      const focusedIndex = inputs.indexOf(document.activeElement);
      if (focusedIndex > -1 && focusedIndex < inputs.length - 1) {
        // Eğer odaklanılan input son input değilse, bir sonrakine odaklan.
        inputs[focusedIndex + 1].focus();
      } else {
        // Eğer odaklanılan input son input ise, formu gönder.
        submitRef.current.click()
      }
    }
  };
  function handleTermsClose(){
    setOpenTerms(false)
  }
  function handleKvkkClose() {
    setOpenKvkk(false);
  }
  function handlePrivacyClose() {
    setOpenPrivacy(false);
  }

  return (
    <Layout
      header={<Header whiteBg={true} />}
      className={"fullscreen auth"}
      noUploader
    >
      <Kvkk handleClose={handleKvkkClose} open={openKvkk}></Kvkk>
      <PrivacyAndCookies handleClose={handlePrivacyClose} open={openPrivacy}></PrivacyAndCookies>
      <TermsOfService handleClose={handleTermsClose} open={openTerms}></TermsOfService>
      <div className="flex" style={{ height: "calc(100vh - 100px)" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          style={{ height: 500, margin: "auto" }}
        >
          <Grid md={5} xs={12} item>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={1} variant="fullWidth">
                <Tab
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="text-gray-600 border-gray-600 capitalize"
                  label={t("Login")}
                />
                <Tab
                  className="text-gray-600 border-gray-600 capitalize"
                  label={t("Register")}
                />
              </Tabs>
            </Box>
            <div style={{ padding: 24 }}>
              {registered !== "" && info.message === "" ? (
                <RegisteredScreen email={registered} />
              ) : (
                <form ref={formRef} onKeyDown={handleKeyDown} onSubmit={handleSubmit(onSubmit)}>
                  <Box className="p-3">
                    <InfoMessage />
                    <div className="mb-4">
                      <Controller
                        name="first_name"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            error={errors.first_name && true}
                            onKeyDown={handleKeyDown}
                            size="small"
                            fullWidth
                            placeholder={t("First Name")}
                            helperText={
                              errors.first_name && errors.first_name.message
                            }
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Person />
                                </InputAdornment>
                              ),
                            }}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="last_name"
                        onKeyDown={handleKeyDown}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            error={errors.last_name && true}
                            size="small"
                            fullWidth
                            placeholder={t("Last Name")}
                            helperText={
                              errors.last_name && errors.last_name.message
                            }
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Person />
                                </InputAdornment>
                              ),
                            }}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            error={errors.email && true}
                            size="small"
                            fullWidth
                            onKeyDown={handleKeyDown}
                            placeholder={t("Email")}
                            helperText={errors.email && errors.email.message}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Email />
                                </InputAdornment>
                              ),
                            }}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            error={errors.password && true}
                            size="small"
                            onKeyDown={handleKeyDown}
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            placeholder={t("Password")}
                            helperText={
                              errors.password && errors.password.message
                            }
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    style={{ padding: 11 }}
                                    onClick={() => {
                                      setShowPassword(!showPassword);
                                    }}
                                    onMouseDown={(e) => {
                                      e.preventDefault();
                                    }}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4 text-left">
                      <p className="w-100 mx-2">

                        Hesap oluşturduğunuzda
                        <span
                          onClick={() => { setOpenTerms(true) }}
                          to="/contracts/terms-of-service"
                          className="underline logo-blue cursor-pointer cursor-pointer"
                        >
                          Hizmet Şartlarımız 
                        </span>
                        <span> ile </span>
                        <span
                          onClick={() => { setOpenPrivacy(true) }}
                          to="/contracts/privacy-and-cookies"
                          className="underline logo-blue cursor-pointer cursor-pointer"
                        >
                          Gizlilik ve Çerez Politikalarımızı
                        </span>
                        <span> kabul etmiş olursunuz.</span>
                        <br></br>
                        <span
                          onClick={() => { setOpenKvkk(true) }}
                          to="/contracts/kvkk"
                          className="underline logo-blue cursor-pointer cursor-pointer"
                        >
                           Kişisel Verilerin Korunmasına İlişkin Aydınlatma Metnini görmek için tıklayınız.
                        </span>
                      </p>
                    </div>
                    <div className="mb-4">
                      <Button
                        ref={submitRef}
                        type="submit"
                        variant="contained"
                        className="rounded-full w-full capitalize"
                        disabled={info.loading?.register}
                        startIcon={
                          info.loading?.register && (
                            <CircularProgress size={"1rem"} />
                          )
                        }
                      >
                        {t("Register")}
                      </Button>
                    </div>
                  </Box>
                </form>
              )}
            </div>
          </Grid>
          <Grid md={7} xs={12} item>
            <Box className="auth-background h-full hidden lg:block"></Box>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};
export default Register;
