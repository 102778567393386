import { Add, Search } from "@mui/icons-material";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LogoLoading from "../../components/LogoLoading";
import Layout from "../../layout";
import Header from "../../layout/Header";
import { getContacts, setParams } from "../../store/contact/contactSlice";
import ContactItem from "./ContactItem";

const Contacts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const info = useSelector((state) => state.info);
  const contactList = useSelector((state) => state.contact.list);
  const [contacts, setContacts] = useState([]);
  const params = useSelector((state) => state.contact.params);

  useEffect(() => {
    if (!contactList) {
      dispatch(getContacts());
    }
    setContacts(contactList || []);
  }, [contactList, dispatch]);

  const localSetParams = (param, value) => {
    let tempParams = { ...params };
    tempParams[param] = value;
    dispatch(setParams(tempParams));
    dispatch(getContacts());
  };

  return (
    <Layout
      header={<Header />}
      sidebarContent
      className={"fullscreen home-image"}
      bgImg={true}

    >
      <>
        <Grid
          container
          justifyContent="center"
          alignItems="start"
          className="border-b pb-5"
        >
          <Grid item lg={7} md={6}>
            <h3 className="text-4xl">{t("Contacts")}</h3>
            <div className="flex justify-start my-2">
              {contacts.length !== 0 && (
                <div className="grid content-center mr-2 text-gray-400">
                  {contacts.length > 1
                    ? t("{{count}} Contacts", { count: contacts.length })
                    : t("1 Contact")}
                </div>
              )}
              <div className="grid content-center ">
                <Button
                  className="normal-case"
                  startIcon={<Add />}
                  style={{ color: "#000" }}
                  onClick={() => setEdit(0)}
                >
                  {t("Add new")}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item lg={5} md={6}>
            <TextField
              id="search"
              size="small"
              onChange={(e) =>
                setTimeout(() => {
                  localSetParams("search", e.target.value);
                }, 1000)
              }
              placeholder={t("Search for name or email")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        {edit === 0 && <ContactItem refreshList={() => {
          dispatch(getContacts())
          setContacts(contactList || []);

        }}
          edit={edit} setEdit={setEdit} />}
        {info.loading?.getContacts ? (
          <LogoLoading />
        ) : (
          <div className="min-h-10 bg-transparent">
            {contacts.map(
              (contact, i) =>
                !!contact?.id && (
                  <ContactItem
                    key={i}
                    id={contact?.id}
                    edit={edit}
                    setEdit={setEdit}
                    data={{
                      full_name: contact.full_name,
                      email: contact.email,
                    }}
                  />
                )
            )}
            {!contacts?.length > 0 && <span className="py-10 flex">Görüntülenecek kayıt bulunamadı.</span>}
          </div>
        )}
      </>
    </Layout>
  );
};
export default Contacts;
