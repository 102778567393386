import { Box, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setAction, setStep } from "../../store/uploader/uploaderSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { verifyEmail } from "../../store/auth/authSlice";
import { Verified } from "@mui/icons-material";

const VerifyEmail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const info = useSelector((state) => state.info);

  useEffect(() => {
    token && dispatch(verifyEmail(token));
  }, [token, dispatch]);

  const confirm  = ()=>{
    dispatch(setStep("form"));
    dispatch(setAction("send"));
    navigate("/login");
  }

  if (!!Object.keys(info.loading)?.length) {
    return <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          width: "100%",
          height: "170px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={60} />
      </Box>
  }

  return (
    <div className="text-center">
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          width: "100%",
          height: "170px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Verified color="success" style={{ fontSize:130 }}/>
      </Box>
      <div className="m-auto my-2" style={{ width: 300 }}>
        <p className="text-2xl">{t("Your email has been verified")}</p>
        <p className="my-2">
          {t("Welcome, now you can easily transfer files without requiring verification.")}
        </p>
      </div>
      <hr />
      <Box className="p-6 flex justify-center">
        <Button
          type="button"
          variant="contained"
          className="rounded-full px-6 normal-case"
          disabled={info.loading?.verifyEmail}
          onClick={() => confirm()}
        >
          {t("Login")}
        </Button>
      </Box>
    </div>
  );
};

export default VerifyEmail;
