import { Close, Email, TurnRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  addRecipient,
  removeRecipient,
  setForward,
  setSender,
  setMessage, setTitle
} from "../../store/uploader/uploaderSlice";
import { useEffect, useState, useRef } from "react";
import fileUploadIcon from "../../assets/images/file-upload.svg";
import folderUploadIcon from "../../assets/images/folder-upload.png";

const UploadForm = ({ handleFileUpload, onSubmit, files }) => {
  const fileUploadRef = useRef();
  const directoryUploadRef = useRef();
  const uploader = useSelector((state) => state.uploader);
  const authUser = useSelector((state) => state.auth.user);
  const [fileNoneError, setFileNoneError] = useState(false)
  const info = useSelector((state) => state.info);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const validation = yup.object({
    sender: yup
      .string()
      .email(t("{{field}} Email is not a valid email", { field: t("Sender") }))
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        t("{{field}} Email is not a valid email", { field: "" }))
      .required(t("{{field}} Email is required", { field: t("Sender") })),
    recipients: yup
      .array()
      .min(
        1,
        t("{{field}} must have at least one item", { field: t("Recipients") })
      ),
    recipient: yup
      .string()
      .email(
        t("{{field}} Email is not a valid email", { field: t("Recipient") })

      ).matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|^$/,
        t("{{field}} Email is not a valid email", { field: "" })
      ),
    message: yup
      .string()
      .max(250, 'Açıklama en fazla 250 karakter olabilir.'),

    title: yup
      .string()
      .max(200, 'Açıklama en fazla 200 karakter olabilir.')
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "all",
    defaultValues: uploader,
    resolver: yupResolver(validation),
  });
  useEffect(() => {
    reset(uploader);
    dispatch(setSender(authUser.email || uploader.sender));

  }, [uploader, reset, authUser, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (files?.length > 0) {
      setFileNoneError(false)
    }
  }, [files])

  const localSubmit = (localData) => {
    dispatch(setSender(localData.sender));
    const tempData = { ...localData };
    tempData.recipients = uploader.recipients;

    if (!files?.length > 0 && !uploader.forward) {
      setFileNoneError(true)
      return null
    } else {
      setFileNoneError(false)
    }
    onSubmit(tempData);
  };

  return (
    <form
      onSubmit={handleSubmit(localSubmit)}
      id="upload-form"
      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
    >
      {uploader.forward && (
        <button
          type="button"
          className="w-full mb-2 mt-6 flex justify-center logo-blue"
          onClick={() => {
            dispatch(setForward(false));
          }}
        >
          <Close size="small" />
          <p>{t("Cancel this forward")}</p>
        </button>
      )}

      {!uploader.forward && (

        <Box className="flex justif-center">

          <label
            // htmlFor="fileUpload"
            className="p-6 px-4 w-full cursor-pointer"
          >
            <Grid container>

              <Grid xs={6} item className="flex justify-center flex-col items-center border-[#707070] border-r-[1px]">
                <label
                  htmlFor="fileUpload"
                  className="underline hover:text-blue-700 cursor-pointer text-center flex flex-col items-center"
                >
                  <img alt="" className="w-[50px]" src={fileUploadIcon}></img>

                  Dosya Yükle
                </label>

              </Grid>
              <Grid xs={6} item className="flex justify-center flex-col items-center">
                <label
                  htmlFor="directoryUpload"
                  className="underline hover:text-blue-700 cursor-pointer text-center flex flex-col items-center"
                >
                  <img alt="" className="w-[50px]" src={folderUploadIcon}></img>

                  Klasör Yükle
                </label>

              </Grid>
            </Grid>
          </label>
          <input
            id="fileUpload"
            name="fileUpload"
            type="file"
            multiple
            ref={fileUploadRef}
            hidden
            onChange={(e) => handleFileUpload(e)}
          />
          <input
            id="directoryUpload"
            hidden
            tabIndex="-1"
            type="file"
            ref={directoryUploadRef}
            multiple
            webkitdirectory="webkitdirectory"
            directory="directory"
            onChange={(e) => handleFileUpload(e)}
          />

        </Box>

      )}
      {fileNoneError && <div className="flex w-full justify-center text-red-500">Lütfen Dosya / Klasör seçiniz.</div>}

      <hr />
      <Box className="p-3">
        <div className="mb-4">
          <label>{t("Recipients")}</label>
          {uploader.recipients.map((item, index) => (
            <div key={index}>
              <Chip
                className="mb-1"
                label={item}
                onDelete={() => {
                  dispatch(removeRecipient(item));
                }}
              />
            </div>
          ))}

          <Controller
            name="recipient"
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  error={typeof errors.recipients !== "undefined"}
                  size="small"
                  fullWidth
                  autoComplete="off"
                  placeholder={t("Email")}

                  helperText={errors.recipient && t(errors.recipient.message)}
                  
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                  onBlur={(e) => {
                    if (!errors.recipient) {
                      dispatch(addRecipient(e.target.value));
                    }
                  }}
                  onKeyDown={(e) => {
                    if (!errors.recipient && e.key === "Enter") {

                      dispatch(addRecipient(e.target.value));
                    }
                  }}
                />
              </>
            )}
          />
          {uploader.errors.recipient && (
            <p
              className="text-[#d32f2f] text-[0.75rem] MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root"
              id=":r3:-helper-text"
            >
              {t(uploader.errors.recipient)}
            </p>
          )}
          {errors.recipients && (
            <p
              className="text-[#d32f2f] text-[0.75rem] MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root"
              id=":r3:-helper-text"
            >
              {t(errors.recipients.message)}
            </p>
          )}
        </div>
        <div className="mb-4">
          <label className="mb-1">{t("Sender")}</label>
          {authUser.email ? (
            <div
              className="flex justify-between border rounded"
              style={{ borderColor: "#c4c4c4" }}
            >
              <span style={{ padding: "8.5px 14px" }}>{authUser.email}</span>
              <div
                style={{
                  width: 24,
                  margin: "auto 13px",
                }}
              >
                <svg viewBox="0 0 24 24">
                  <path
                    style={{ fill: "#757575" }}
                    d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z"
                  ></path>
                </svg>
              </div>
            </div>
          ) : (
            <Controller
              name="sender"
              control={control}
              render={({ field }) => (
                <TextField
                  error={typeof errors.sender !== "undefined"}
                  size="small"
                  autoComplete="off"
                  fullWidth
                  className="mt-1"
                  placeholder={t("Email")}
                  helperText={errors.sender && t(errors.sender.message)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                  onBlur={(e) => {
                    if (!errors.sender) {
                      dispatch(setSender(e.target.value));
                    }
                  }}
                />
              )}
            />
          )}
        </div>
        <div className="mb-4">
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                autoComplete="off"
                fullWidth
                placeholder={t("Title")}
                {...field}
                onBlur={(e) => {
                  dispatch(setTitle(e.target.value));

                }}
              />
            )}
          />
          {errors.title && (
            <p
              className="text-red-500 MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root"
              id=":r3:-helper-text"
            >
              {t(errors.title.message)}
            </p>
          )}
        </div>
        <div className="mb-4">
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                size="small"
                multiline
                minRows={4}
                maxRows={10}
                placeholder={t("Description")}

                {...field}
                onBlur={(e) => {
                  dispatch(setMessage(e.target.value));

                }}
              />
            )}
          />
          {errors.message && (
            <p
              className="text-red-500 MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root"
              id=":r3:-helper-text"
            >
              {t(errors.message.message)}
            </p>
          )}
        </div>
      </Box>
      <hr />
      <Box className="p-6 flex justify-center">
        <Button
          className="bg-primary rounded-full px-6"
          type="submit"
          variant="contained"
          disabled={(!(files?.length > 0)) & !uploader.forward || !isValid }
          startIcon={
            info.loading?.forwardUploads || info.loading?.beginUploads ? (
              <CircularProgress style={{color:'white'}} size={"1rem"} />
            ) : (
              <>{uploader.forward ? <TurnRight /> : <></>}</> //<Send />
            )
          }
        >
          {uploader.forward ? t("Forward") : t("Transfer")}
        </Button>
      </Box>
    </form>
  );
};

export default UploadForm;
