import {
  ArrowBack,
  Close,
  FileDownloadOutlined,
  Search,
  TurnRight,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LogoLoading from "../../components/LogoLoading";
import Layout from "../../layout";
import Header from "../../layout/Header";
import { onSidebarCloseNavigate, setSuccess } from "../../store/info/infoSlice";
import { sidebarOpen } from "../../store/sidebar/sidebarSlice";

import {
  deleteTransfer,
  getTransfer,
  getStatus,
  startDownload,
  update,
} from "../../store/transfer/transferSlice";
import { setAction, setForward } from "../../store/uploader/uploaderSlice";
import { printSize, readableDate } from "../../utils";
import ConfirmDialog from "../../components/ConfirmDialog";

const Single = () => {

  const navigate = useNavigate();
  const { id, direction } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const transfer = useSelector((state) => state.transfer.item);
  const info = useSelector((state) => state.info);
  const uploader = useSelector((state) => state.uploader);
  const auth = useSelector((state) => state.auth);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  useEffect(() => {
    transfer?.id !== id && dispatch(getTransfer({ id, direction }));
    if (transfer?.status !== "active" && !info.loading?.getStatus) {
      setTimeout(() => {
        dispatch(getStatus(id));
      }, 1000);
    }
    dispatch(sidebarOpen());
    dispatch(onSidebarCloseNavigate(`/`));
  }, [dispatch, id, direction, transfer, info.loading?.getStatus]);

  useEffect(() => {
    info.code === 406 && navigate("/transfers");
  }, [navigate, info]);

  const download = (type = "entire", file_id = null, name = transfer.name) => {
    let tempTransfer = { ...transfer };
    if (tempTransfer.downloads == 'undefined' || tempTransfer.downloads == undefined || tempTransfer.downloads == null || tempTransfer.downloads == 'null') {
      tempTransfer.downloads = 1;
    } else {
      tempTransfer.downloads++;

    }
    dispatch(
      startDownload({ id, type, file_id, email: auth.user.email, name })
    );
    dispatch(update(tempTransfer));
  };

  const preview = () => {
    const encoded = window.btoa(auth.user.email);
    navigate("/downloads/" + id + "/" + encoded + "/preview");
  };

  const del = () => {
    setOpenConfirmDialog(true)
  };
  const agreeDelete = () => {
    dispatch(deleteTransfer(id));
    navigate("/transfers");
  }
  const forward = () => {
    dispatch(setAction("new"));
    dispatch(setForward(true));
  };
  function handleCorfimDialogClose() {
    setOpenConfirmDialog(false)
  }

  return (
    <Layout
      header={<Header />}
      sidebarContent
      className={"fullscreen home-image"}
      bgImg={true}

    >
      <ConfirmDialog handleClose={handleCorfimDialogClose} agree={agreeDelete} open={openConfirmDialog} />

      <div className="p-5 relative h-full">
        <div className="flex justify-start mb-10">
          <Button className="normal-case"
            startIcon={<ArrowBack />}
            style={{ color: "#000" }}
            onClick={() => {
              navigate('/transfers')
            }}
          >
            Geri Dön
          </Button>
        </div>
        {!transfer || info.loading?.getTransfer ? (
          <LogoLoading />
        ) : (
          <div>

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="pb-5 border-b"
            >

              <Grid item lg={12} md={12}>
                <h3 className="text-4xl">{transfer.title}</h3>
                <div className="flex justify-start my-2">
                  <div className="text-gray-400">
                    <small className="pr-1 after:content-['·'] after:ml-1">
                      {transfer.files?.length === 1
                        ? t("1 file")
                        : t("{{count}} files", {
                          count: transfer.files?.length,
                        })}
                    </small>
                    <small className="pr-1">
                      {t("Sending date")}: {readableDate(transfer.created_at)}
                    </small>
                  </div>
                </div>
              </Grid>
            </Grid>
            {transfer?.expire?.status === false && (
              <div className="flex justify-between py-5 border-b">
                <div className="grid content-center">
                  <OutlinedInput
                    id="url"
                    value={transfer.url}
                    size="small"
                    readOnly
                    endAdornment={
                      <InputAdornment
                        className="cursor-pointer"
                        position="end"
                        onClick={() => {
                          navigator.clipboard.writeText(transfer.url);
                          dispatch(
                            setSuccess(
                              t("This link has been copied to your clipboard.")
                            )
                          );
                        }}
                      >
                        <button className="logo-blue">{t("copy link")}</button>
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                  />
                </div>
                <div>
                  {auth.isLoggedIn && (
                    <>
                      <button
                        className="logo-blue ml-5"
                        disabled={transfer?.status !== "active"}
                        onClick={() => {
                          transfer.files?.length === 1
                            ? download("single", transfer.files[0]?.id)
                            : download();
                        }}
                      >
                        <div>
                          <FileDownloadOutlined />
                        </div>
                        <div className="text-sm">{t("Download all")}</div>
                      </button>
                      <button
                        className="logo-blue ml-5"
                        onClick={preview}
                        disabled={transfer?.status !== "active"}
                      >
                        <div>
                          <Search />
                        </div>
                        <div className="text-sm">{t("Preview")}</div>
                      </button>
                      {direction === "sent" && (
                        <>
                          <button
                            className="logo-blue ml-5"
                            onClick={forward}
                            disabled={transfer?.status !== "active"}
                          >
                            <div>
                              <TurnRight />
                            </div>
                            <div className="text-sm">{t("Forward")}</div>
                          </button>
                          <button
                            className="logo-blue ml-5"
                            onClick={del}
                            disabled={
                              uploader.forward || transfer?.status !== "active"
                            }
                          >
                            <div>
                              <Close />
                            </div>
                            <div className="text-sm">{t("Delete")}</div>
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            <Grid
              container
              justifyContent="center"
              alignItems="top"
              className="py-5 "
            >
              <Grid item md={6}>
                {direction === "received" && (
                  <div className="mb-8">
                    <p className="mb-4">{t("Received from")}</p>
                    <div
                      key={transfer.email}
                      className="mb-2 flex justify-start"
                    >
                      <Avatar
                        sx={{ width: 25, height: 25, margin: "5px" }}
                        src={transfer?.user?.avatar}
                      />
                      <div className="grid content-center">
                        <p className="font-light">{transfer.email}</p>
                      </div>
                    </div>
                  </div>
                )}
                {direction === "sent" && (
                  <>
                    <div className="mb-8">
                      <p className="mb-4">
                        {transfer.recipients?.length === 1
                          ? t("Sent to 1 person")
                          : t("Sent to {{count}} people", {
                            count: transfer.recipients?.length,
                          })}
                      </p>
                      {transfer.recipients &&
                        transfer.recipients.map((item) => (
                          <div
                            key={item.email}
                            className="mb-2 flex justify-start"
                          >
                            <Avatar
                              sx={{ width: 25, height: 25, margin: "5px" }}
                              src={item.avatar}
                            />
                            <div className="grid content-center">
                              <p className="font-light">{item.email}</p>
                              <small className="font-thin">
                                {!item.download_at
                                  ? t("Not yet downloaded")
                                  : t("Downloaded on {{date}}", {
                                    date: readableDate(item.download_at),
                                  })}
                              </small>
                            </div>
                          </div>
                        ))}
                    </div>
                    {auth.isLoggedIn && (
                      <div className="mb-8">
                        <p className="mb-0">{t("Total Download")}</p>
                        <p className="text-8xl">{transfer.downloads}</p>
                      </div>
                    )}
                  </>
                )}
              </Grid>
              <Grid item md={6}>
                <div className="mb-8">
                  <p className="mb-4">
                    {transfer.files?.length === 1
                      ? t("1 file")
                      : t("{{count}} files", {
                        count: transfer.files?.length,
                      })}
                  </p>
                  {transfer.files &&
                    transfer.files.map((item) => (
                      <div
                        key={item.id}
                        className="pb-2 mb-1 border-b flex justify-between"
                      >
                        <div>
                          <p className="font-light">{item.name}</p>
                          <div className="text-gray-400 font-thin text-left">
                            <small className="pr-1">
                              {printSize(item.size)}
                            </small>
                          </div>
                        </div>
                        <div className="grid content-center">
                          {auth.isLoggedIn &&
                            transfer?.expire?.status === false && (
                              <IconButton
                                onClick={() =>
                                  download("single", item.id, item.name)
                                }
                              >
                                <FileDownloadOutlined />
                              </IconButton>
                            )}
                        </div>
                      </div>
                    ))}
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Single;
