import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../configs/axios";
import { debug, downloadFile } from "../../utils";
import {
  resetLoading,
  setInfo,
  setLoading,
  setSuccess,
} from "../info/infoSlice";

export const getTransfers = createAsyncThunk(
  "transfers/getTransfers",
  async (direction, { dispatch, getState }) => {
    dispatch(setLoading("getTransfers"));
    const params = new URLSearchParams();
    params.set("direction", direction);
    params.set("search", getState().transfer[direction].params.search);
    params.set("start", getState().transfer[direction].params.start);
    params.set("count", getState().transfer[direction].params.count);
    params.set("sort", getState().transfer[direction].params.sort);
    const response = await axios.get("/transfers?" + params.toString());
    debug("getTransfers", response);
    dispatch(resetLoading("getTransfers"));
    if (response.status === 200) {
      return { response: response.data, direction };
    }
  }
);

export const addTransfer = createAsyncThunk(
  "transfers/addTransfer",
  async (transfer, { dispatch, getState }) => {
    dispatch(setLoading("addTransfer"));
    let response = await axios.post("/transfers", transfer);
    debug("addTransfer", response);
    dispatch(resetLoading("addTransfer"));
    if (response.status === 201) {
      dispatch(
        setSuccess({
          message: "Transfer created",
        })
      );
      return transfer;
    }
  }
);

export const getTransfer = createAsyncThunk(
  "transfers/getTransfer",
  async ({ id, direction }, { dispatch }) => {
    dispatch(setLoading("getTransfer"));
    let response = await axios.get(`/transfers/${id}/${direction}`);
    dispatch(resetLoading("getTransfer"));
    if (response.status === 200) {
      if (response.data?.expire?.status === true) {
        dispatch(setInfo(response.data?.expire?.reason));
      }
      return { ...response.data, direction };
    }
  }
);

export const getStatus = createAsyncThunk(
  "transfers/getStatus",
  async (id, { dispatch }) => {
    dispatch(setLoading("getStatus"));
    let response = await axios.get(`/transfers/${id}/status`);
    dispatch(resetLoading("getStatus"));
    if (response.status === 200) {
      return response.data?.status;
    }
  }
);

export const editTransfer = createAsyncThunk(
  "transfers/editTransfer",
  async (obj, { dispatch, getState }) => {
    dispatch(setLoading("editTransfer"));
    const { id, ...data } = obj;
    let response = await axios.put(`/transfers/${id}`, data);
    debug("editTransfer", response);
    dispatch(resetLoading("editTransfer"));
    if (response.status === 200) {
      dispatch(
        setSuccess({
          message: "Transfer updated",
        })
      );
      return response.data;
    }
  }
);

export const deleteTransfer = createAsyncThunk(
  "transfers/deleteTransfer",
  async (id, { dispatch, getState }) => {
    dispatch(setLoading("deleteTransfer"));
    let response = await axios.delete(`/transfers/${id}`);
    debug("deleteTransfer", response);
    dispatch(resetLoading("deleteTransfer"));
    if (response.status === 204) {
      dispatch(
        setSuccess({
          message: "Aktarım silindi.",
        })
      );
      return { id };
    }
  }
);

export const startDownload = createAsyncThunk(
  "transfers/startDownload",
  async (obj, { dispatch, getState }) => {
    dispatch(setLoading("startDownload"));
    const { id, ...data } = obj;
    let response = await axios.post(`/transfers/${id}/download`, data);
    debug("startDownload", response);
    if (response.status === 200) {
      const filename =
        data.type === "single" ? data.name : "uzmantransfer_" + data.name;
      downloadFile(response.data?.download_url, filename);
    }
    dispatch(resetLoading("startDownload"));
  }
);

export const previewTransfer = createAsyncThunk(
  "transfers/previewTransfer",
  async (obj, { dispatch, getState }) => {
    dispatch(setLoading("previewTransfer"));
    const { id, ...data } = obj;
    let response = await axios.post(`/transfers/${id}/preview`, data);
    debug("previewTransfer", response);
    dispatch(resetLoading("previewTransfer"));
    if (response.status === 200) {
      if (response.data?.expire?.status === true) {
        dispatch(setInfo(response.data?.expire?.reason));
      }
      return response.data;
    }
  }
);

const initialState = {
  sent: {
    total: 0,
    params: { search: "", sort: "created_at", start: 0, count: 20 },
    list: null,
  },
  received: {
    total: 0,
    params: { search: "", sort: "created_at", start: 0, count: 20 },
    list: null,
  },
  item: null,
};

export const transferSlice = createSlice({
  name: "transfer",
  initialState,
  reducers: {
    update: (state, action) => {
      state.item = action.payload;
    },
    setParams: (state, action) => {
      const { direction, ...params } = action.payload;
      state[direction].params = params;
    },
    resetTransferState: (state) => {
      state.sent = initialState.sent;
      state.received = initialState.received;
      state.item = initialState.item;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransfers.fulfilled, (state, action) => {
        const { direction, response } = action.payload;
        state[direction].total = response.total;
        state.item = null;

        if (!!state[direction].params.search) {
          state[direction].list = response.data;
        } else if (response.total > (state[direction].list?.length || 0)) {
          const diff = (state[direction].list || []).filter(
            (item) => -1 === response.data?.findIndex((el) => el.id === item.id)
          );
          state[direction].list = [...diff, ...response.data];
        }
      })
      .addCase(getTransfer.fulfilled, (state, action) => {
        const { direction, ...data } = action.payload;
        state.item = data;
        const index = state[direction].list?.findIndex(
          (item) => item.id === data.id
        );
        if (index === undefined) {
          state[direction].list = [];
          state[direction].list?.unshift(data);
        } else if (index === -1) {
          state[direction].list?.unshift(data);
        } else {
          state[direction].list[index] = data;
        }
        state[direction].total = state[direction].list?.length || 0;
      })
      .addCase(getStatus.fulfilled, (state, action) => {
        state.item.status = action.payload;
      })
      .addCase(previewTransfer.fulfilled, (state, action) => {
        state.item = action.payload;
      })
      .addCase(deleteTransfer.fulfilled, (state, action) => {
        const index = state.sent.list?.findIndex(
          (item) => item?.id === action.payload?.id
        );
        state.sent.list?.splice(index, 1);
        state.sent.total = state.sent.total - 1;
      });
  },
});

export const { update, setParams, resetTransferState } = transferSlice.actions;
export default transferSlice.reducer;
